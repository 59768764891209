import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { dateService } from '../domain/services/dateService';
import { t } from '../domain/services/configService';
import { SmartLink } from './SmartLink';
import { cardAspect, cardSize } from './Styles/card';
import { theme } from '../domain/theme';

const card = css`
  position: relative;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #000;
  float: left;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    cursor: default;
  }

  &:hover .card__text {
    opacity: 0.8;
    color: #999;
  }

  &:hover .card__play-button {
    background: #fc0;
  }

  &:hover .card__play-button:after {
    border-left-color: #000 !important;
  }
`;

const chanCard = css`
  & .card__schedule--link {
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: normal;
  }

  & .card__schedule--link,
  & .card__schedule--time {
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 1.5;
    font-style: normal;
  }

  & .card__schedule--time {
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 2.5px;
    line-height: 1.5;
  }

  & .card__header .show-tit {
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    letter-spacing: 2.5px;
    line-height: 1.5;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }

  & .card__header .show-tit {
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    font-size: 1.375rem;
    letter-spacing: 2.5px;
    line-height: 1.5;
  }

  width: 100%;
  overflow: hidden;
  background: #000;
  margin-bottom: 30px;

  &.card--1x1 {
    padding-bottom: 100%;
  }

  &.card--4x3 {
    padding-bottom: 75%;
  }

  &.card--16x9 {
    padding-bottom: 56.25%;
  }

  &:before {
    content: '';
    position: absolute;
    width: 90px;
    height: 80px;
    background: #fff;
    opacity: 0.15;
    top: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 90px;
    height: 80px;
    background: #fff;
    opacity: 0.15;
    bottom: 0;
    right: 0;
  }

  & .card__header {
    position: absolute;
    left: calc(100% - 70px);
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  & .card__header:before {
    content: '';
    position: absolute;
    background: #fc0;
    height: 18px;
    width: 3px;
    display: block;
    margin: 4px 0 0 -30px;
  }

  & .card__header .show-tit {
    color: #fff;
    display: inline-block;
    line-height: 1.6875rem;
    width: 200px;
  }

  & .card__cont--tit {
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 3px;
    line-height: 1.5;
    position: absolute;
    left: 50px;
    bottom: 95%;
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
  }

  & .card__cont--tit span {
    color: #fc0;
  }

  & .card__schedule--link {
    display: none;
    position: absolute;
    bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: 9;
  }

  & .card__schedule--link a {
    color: #fff;
    text-decoration: none;
  }

  & .card__schedule--link a:hover {
    cursor: pointer;
  }

  & .card__schedule--time {
    color: #fff;
    position: absolute;
    left: 50%;
    margin: 10px 0 0 20px;
    text-transform: uppercase;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  & .card__seasons {
    color: #999;
  }

  ${theme.mq.tablet} {
    & .card__header {
      left: calc(100% - 55px);
    }

    & .card__header .show-tit {
      font-size: 1.1875rem;
      line-height: 1.375rem;
    }

    & .card__header:before {
      height: 14px;
      margin-top: 2px;
      top: 0;
    }

    & .card__schedule--time {
      font-size: 0.625rem;
    }

    &:before {
      width: 90px;
      height: 80px;
    }

    &:after {
      width: 90px;
      height: 80px;
    }
  }

  ${theme.mq.small_desktop} {
    & .card__schedule--time {
      bottom: 32%;
    }

    & .card__header {
      width: 100%;
    }

    & .card__header .show-tit {
      font-size: 1.375rem;
      line-height: 1.625rem;
    }

    & .card__header a:hover,
    & .card__header a:hover + div {
      opacity: 0.6;
    }

    & .card__header:before {
      height: 17px;
    }

    & .card__schedule--link a:hover {
      color: #fc0;
    }

    & .card__cont--tit {
      margin-bottom: 10px;
    }

    &:before {
      width: 120px;
      height: 110px;
    }

    &:after {
      width: 120px;
      height: 110px;
    }
  }

  ${theme.mq.large_desktop} {
    &:before {
      width: 160px;
      height: 150px;
    }

    &:after {
      width: 160px;
      height: 150px;
    }

    & .card__header {
      left: calc(100% - 90px);
      top: 45%;
      width: 100%;
    }

    & .card__header .show-tit {
      font-size: 1.375rem;
      line-height: 1.625rem;
    }

    & .card__header:before {
      height: 17px;
    }

    & .card__cont--tit {
      left: 100px;
      margin-bottom: 5px;
    }

    & .card__schedule--time {
      font-size: 0.75rem;
      margin-top: 0;
    }

    & .card__schedule--link {
      bottom: 30px;
      display: inline;
    }
  }
`;

const chanCardScheduleTime = css`
  ${theme.mq.small_desktop} {
    & {
      font-size: 0.75rem;
    }
  }
`;

export const ChannelScheduleCard = ({
  size,
  aspect,
  url,
  pageStatus,
  header,
  time
}) =>
  header ? (
    <div
      className={`card chan-card card--${size} card--${aspect}`}
      css={[chanCard, card, cardAspect[aspect], cardSize[size]]}
    >
      <div className="card__header">
        {url && pageStatus === '1' ? (
          <SmartLink to={url} className="show-tit">
            {header}
          </SmartLink>
        ) : (
          <span className="show-tit">{header}</span>
        )}

        <div className="card__schedule--time" css={chanCardScheduleTime}>
          {typeof time === 'number'
            ? dateService.format(time, 'show_time')
            : time}
        </div>
      </div>

      <div className="card__schedule--link">
        <SmartLink to={t('/television-schedule')}>
          {t('View all schedules')}
        </SmartLink>
      </div>
    </div>
  ) : null;

ChannelScheduleCard.defaultProps = {
  url: undefined,
  header: undefined
};

ChannelScheduleCard.propTypes = {
  size: PropTypes.string.isRequired,
  aspect: PropTypes.string.isRequired,
  url: PropTypes.string,
  header: PropTypes.string,
  pageStatus: PropTypes.string.isRequired,
  time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
